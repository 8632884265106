import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}
const AnalyticalTools = ({ match }: iProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={`${match.url}/allocation_map`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_AllocationMap')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/mtd_performance_report`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_MTDPerformanceReport/index'
          )
      )}
    ></Route>
      <Route
      exact
      path={`${match.url}/monthly_fund_performance`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_MonthlyFundPerfomance/index'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/index_return`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_HistoricalDataDownload/IndexReturnData'
          )
      )}
    ></Route>
       <Route
      exact
      path={`${match.url}/portfolio_static`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_HistoricalDataDownload/PortfolioStatisData'
          )
      )}
    ></Route>
     <Route
      exact
      path={`${match.url}/strategy_allocation_contributions`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_StrategyAllocationWar'
          )
      )}
    ></Route>
        <Route
      exact
      path={`${match.url}/war_report`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_WARReport')
      )}
    ></Route>
  </Switch>
)
export default AnalyticalTools
